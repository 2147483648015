.body {
    background-color: ivory;
    width: 100%;
    height: 100%;
    display: flex;
    /* width: 100%; */
    /* color: white; */
    justify-content: center;
    /* flex-wrap: wrap; */
    align-items: center;
    /* display: flex; */
    flex-direction: column;
}

.content {
    /* font-family: serif; */
    /* 设置字体 */
    /* 设置标题背景色 */
    width: 1100px;
    /* justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    display: flex; */
    /* 设置标题字体大小 */
    /* font-weight: bold; */
    /* 设置标题字体粗细 */

}

.Text {
    font-size: 20px;
}

.pg-text-policy {
    font-size: 20px;

}