.app {
    /* text-align: center; */
    width: 100%;
    background-color: ivory;
}


.title {
    font-family: serif;
    /* 设置字体 */
    /* 设置标题背景色 */
    text-align: center;
    /* 设置标题字体大小 */
    font-weight: bold;
    /* 设置标题字体粗细 */
    font-size: 60px;
}

.title-text {
    /* font-family: serif; */
    /* 设置字体 */
    /* 设置标题背景色 */
    width: 1100px;
    /* justify-content: center; */
    text-align: center;
    /* flex-wrap: wrap; */
    align-items: center;
    display: flex;
    /* 设置标题字体大小 */
    /* font-weight: bold; */
    /* 设置标题字体粗细 */
    font-size: 25px;
}

.buttonShop {
    width: 130px;
    height: 50px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    border-color: blue;
    border-width: 2px;
}

.margin-right {
    margin-right: 180px;
}

.button-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}



.content-title-text {
    /* font-family: serif; */
    /* 设置字体 */
    /* 设置标题背景色 */
    width: 1100px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    /* 设置标题字体大小 */
    font-weight: bold;
    /* 设置标题字体粗细 */
    font-size: 22px;
}

.content-text {
    /* font-family: serif; */
    /* 设置字体 */
    /* 设置标题背景色 */
    width: 1100px;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    /* 设置标题字体大小 */
    /* font-weight: bold; */
    /* 设置标题字体粗细 */
    font-size: 18px;
}

.content-text-1 {
    /* font-family: serif; */
    /* 设置字体 */
    /* 设置标题背景色 */
    width: 1100px;
    justify-content: center;
    /* text-align: center; */
    flex-wrap: wrap;
    /* align-items: center; */
    display: flex;
    /* 设置标题字体大小 */
    /* font-weight: bold; */
    /* 设置标题字体粗细 */
    font-size: 18px;
}

.space {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}

.content {
    /* backgroundColor: '#4096ff', */
    /* height: 80px; */
    display: flex;
    background-color: ivory;
    /* width: 100%; */
    /* color: white; */
    justify-content: center;
    /* flex-wrap: wrap; */
    align-items: center;
    /* display: flex; */
    flex-direction: column;
}


/* .Flex {
    background-color: rgb(223, 45, 45);
    width: 100%;
  }
  
  .Cotent {
    background-color: rgb(223, 45, 45);
  } */