.row-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    /* background-color:blueviolet; */
}





/* .content {
    background-color:beige;
} */

.col-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.card {
    width: 320px;
    height: 470px;
    border-radius: 5px;
    background-color: ivory;
    border: 2px solid #308de9;
    font-size: 16px;
}

.button {
    width: 100%;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .card_price{
    
} */